<template>
  <div class="AddressTag">
    <div class="addressTitle">{{$t('lang.SText39')}}</div>
    <div class="dw-card2">
      <div class="title">{{$t('lang.SText42')}}</div>
      <div class="main">
        <el-tag type="info" v-for="tag in system" :key="tag.id">{{
          tag.name
        }}</el-tag>
      </div>
    </div>
    <div class="dw-card2">
      <div class="title">
        <div class="titleDiv">
          {{$t('lang.SText43')}}
          <div class="tagNum">{{ customized.length }}/10</div>
        </div>
        <dw-button
          @click="clickAddTag"
          class="right-button"
          type="primarySmall"
          isAdd
          >{{$t('lang.SText44')}}</dw-button
        >
      </div>

      <div class="main">
        <el-tag
          closable
          v-for="item in customized"
          :key="item.id"
          @close="closeTag(item)"
          >{{ item.name }}</el-tag
        >
      </div>
    </div>
    <dw-check-box
      :showCheckBox="closeFlag"
      @Cancel="Cancel"
      @Save="deleteTag()"
      :primaryButton="$t('lang.BText40')"
      :CancelButton="$t('lang.QText79')"
    >
      {{$t('lang.SText45')}} "{{deleteTagName}}" {{$t('lang.SText46')}}
    </dw-check-box>
    <dw-check-box :showCheckBox="showAdd" @Cancel="Cancel" @Save="SaveNewTag">
      {{$t('lang.SText47')}}<br />
      {{$t('lang.SText48')}} *
      <el-form :rules="rules" :model="form">
        <el-form-item class="form-item" prop="newTagData" label="">
          <el-input v-model="form.newTagData"></el-input>
        </el-form-item>
      </el-form>
    </dw-check-box>
  </div>
</template>

<script>
import DwCheckBox from "../../../components/dwCheckBox/dwCheckBox.vue";
export default {
  components: {
    DwCheckBox,
  },
  data() {
    return {
      system: [],
      customized: [],
      closeFlag: false,
      delectId: null,
      showAdd: false,
      form: { newTagData:"" },
      userInfo: [],
      rules: {
        newTagData: [{ max: 30, message: this.$t('lang.SText148'), trigger: "change" }],
      },
      deleteTagName: ""
    };
  },
  created() {
    this.$session("userInfo").get((value) => {
      this.userInfo = value;
      this.tagList();
    });
  },
  methods: {
    closeTag(data) {
      this.closeFlag = true;
      this.delectId = data.id;
      this.deleteTagName = data.name;
    },
    Cancel() {
      this.closeFlag = false;
      this.showAdd = false;
    },
    deleteTag() {
      let that = this;
      that.$ajax
        .cPost(that.source.address_api.delTag, {
          id: this.delectId,
          companyId: this.userInfo.companyid,
        })
        .then((res) => {
          that.closeFlag = false;
          if (res.data.result == "false") {
            that.$notify.error({
              title: res.data.message,
              offset: 100,
              duration: 3000,
            });
          } else {
            that.tagList();
            that.$notify({
              title: this.$t('lang.SText149'),
              type: "success",
              offset: 100,
              duration: 3000,
            });
          }
        });
    },
    clickAddTag() {
      this.form.newTagData = null;
      this.showAdd = true;
    },
    SaveNewTag() {
      if (this.form.newTagData == null||this.form.newTagData.trim() == '') {
        this.$notify({
          title: this.$t('lang.SText150'),
          type: "error",
          offset: 100,
        });
        return;
      }
      this.$ajax
        .cPost(this.source.address_api.addTag, {
          addressTag: this.form.newTagData,
          companyId: this.userInfo.companyid,
        })
        .then((res) => {
          if (res.data.result == "false") {
            this.$notify.error({
              title: res.data.message,
              offset: 100,
              duration: 3000,
            });
          } else {
            this.showAdd = false;
            this.tagList();
            this.$notify({
              title: this.$t('lang.SText151'),
              type: "success",
              offset: 100,
              duration: 3000,
            });
          }
        });
    },
    tagList() {
      //返回数据
      this.$ajax
        .cPost(this.source.address_api.tagList, {
          companyId: this.userInfo.companyid,
        })
        .then((res) => {
          if (res.data.result == "false") {
            this.$notify.error({
              title: res.message,
              offset: 100,
              duration: 3000,
            });
          } else {
            this.system = res.data.system;
            this.customized = res.data.customized;
          }
        });
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/base.scss";
.AddressTag {
  width: 900px;
  margin-left: 150px;
  .addressTitle {
    text-align: left;
    font-size: 30px;
    color: $mainColor;
    margin: 10px 0 20px 0;
  }
  .dw-card2 {
    .main {
      text-align: left;
    }
  }
  .tagNum {
    font-size: 20px;
    margin-left: 20px;
  }
  .titleDiv {
    display: flex;
  }
}
</style>